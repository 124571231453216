import React from "react";
import styled from "styled-components";

import BubblesImage from "../../assets/images/bubbles.png";

const ProgressWrapper = styled.div`
  ${"" /* width: 60%; */}
  height: 20px;
  ${"" /* background-color: grey; */}

  border-radius: 5px;
  overflow: hidden;

  .progress {
    height: 100%;
    position: relative;
    ${"" /* background-color: ${(props) => props.barColour}; */}
    background: url(${BubblesImage});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &:after {
      width: 100%;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(${(props) => props.progress * 100}%);
      transition: transform 120ms ease-in-out;
      height: 100%;
      background-color: #fff;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const LineLoader = ({ progress, barColour }) => {
  return (
    <ProgressWrapper
      barColour={barColour}
      progress={progress}
      className='line-loader-wrapper'
    >
      {/* <div style={{ left: `-${100 - progress * 100}%` }} className='progress'> */}
      <div className='progress'>
        {/* <img src={BubblesImage} alt='Bubbles' /> */}
      </div>
    </ProgressWrapper>
  );
};

export default LineLoader;
