import create from "zustand";

const useAppStore = create((set) => ({
  currentSlide: {},
  currentIndex: 0,
  onBoardingVO: "",
  currentVO: "",
  showSpeechBubble: false,

  hasPlayedLandingVO: false,

  setHasPlayedLandingVO: (hasPlayedLandingVO) =>
    set((state) => ({ ...state, hasPlayedLandingVO })),

  setCurrentSlide: (currentSlide) =>
    set((state) => ({ ...state, currentSlide })),

  setCurrentIndex: (currentIndex) =>
    set((state) => ({ ...state, currentIndex })),

  setOnBoardingVO: (onBoardingVO) =>
    set((state) => ({ ...state, onBoardingVO })),

  setCurrentVO: (currentVO) => set((state) => ({ ...state, currentVO })),

  setShowSpeechBubble: (showSpeechBubble) =>
    set((state) => ({ ...state, showSpeechBubble })),
}));

export const useCurrentSlide = () => useAppStore((state) => state.currentSlide);
export const useCurrentIndex = () => useAppStore((state) => state.currentIndex);
export const useOnBoardingVO = () => useAppStore((state) => state.onBoardingVO);
export const useCurrentVO = () => useAppStore((state) => state.currentVO);
export const useShowSpeechBubble = () =>
  useAppStore((state) => state.showSpeechBubble);

export const useHasPlayedLandingVO = () =>
  useAppStore((state) => state.hasPlayedLandingVO);

export const useSetHasPlayedLandingVO = () =>
  useAppStore((state) => state.setHasPlayedLandingVO);

export const useSetCurrentSlide = () =>
  useAppStore((state) => state.setCurrentSlide);
export const useSetCurrentIndex = () =>
  useAppStore((state) => state.setCurrentIndex);
export const useSetOnBoardingVO = () =>
  useAppStore((state) => state.setOnBoardingVO);
export const useSetCurrentVO = () => useAppStore((state) => state.setCurrentVO);
export const useSetShowSpeechBubble = () =>
  useAppStore((state) => state.setShowSpeechBubble);
