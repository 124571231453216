import React, { useState } from "react";
import SydneyWaterLogo from "../../assets/images/sydney-water-logo.svg";
import WondersOfWaterLogo from "../../assets/images/wonders-of-water-logo.svg";

const Browser = () => {
  const [isCopied, setIsCopied] = useState(false);
  const url = window.location.href;

  const copyToClipboard = () => {
    if (!url) return;

    if (window.navigator?.clipboard) {
      window.navigator.clipboard
        .writeText(url)
        .then(() => setIsCopied(true))
        .catch((err) => console.warn("Copy to clipboard failed.", err));
    } else {
      let tempInput = document.createElement("textarea");
      tempInput.textContent = url;
      tempInput.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(tempInput);
      tempInput.select();

      try {
        let cliptry = document.execCommand("copy"); // Security exception may be thrown by some browsers.
        if (cliptry) {
          setIsCopied(true);
        }
        return cliptry;
      } catch (err) {
        console.warn("Copy to clipboard failed.", err);
        return false;
      } finally {
        document.body.removeChild(tempInput);
      }
    }
  };

  return (
    <div className='container'>
      <img
        src={SydneyWaterLogo}
        alt='Sydney Water logo'
        className='sydney-water-logo'
      />

      <div className='body-wrapper'>
        <p>
          Your browser does not support the features required for WebAR. Click
          the button below to copy the link, then paste it to a supported
          browser.
        </p>
        <p className='url-text'>{window.location.href}</p>
        <button className='btn btn-copy' onClick={copyToClipboard}>
          {isCopied ? "Link Copied" : "Copy Link Now!"}
        </button>
      </div>

      <img
        src={WondersOfWaterLogo}
        alt='Wonders of Water logo'
        className='wonders-water-logo'
      />
    </div>
  );
};

export default Browser;
