import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useLayoutEffect
} from "react";
import styled from "styled-components";
import gsap from "gsap";

import { ConfigContext } from "../../global/ConfigState";
import CookiePrompt from "../../components/helpers/CookiePrompt";
import DefaultBtn from "../../components/layout/DefaultBtn";

import SydneyWaterLogo from "../../assets/images/sydney-water-logo.svg";
import WondersOfWaterLogo from "../../assets/images/wonders-of-water-logo.svg";
import BigBottomIntroWaveImage from "../../assets/images/bottom-intro-wave_big.svg";
import TopIntroWaveImage from "../../assets/images/top-intro-wave.svg";
import WhiteSydneyWaterLogo from "../../assets/images/white-sydney-water-logo.svg";
import platyLoop_typeTwo from "../../assets/videos/loop_typeTwoV2.png";

import RangeSliderPlaty from "../../assets/images/range-slider-platy.png";

import voIntro from "../../assets/VO/1_intro.mp3";
import voSurvey from "../../assets/VO/2_survey.mp3";
import { useSetOnBoardingVO, useOnBoardingVO } from "../../global/AppStore";

const LandingWrapper = styled.section`
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: auto;
  overflow: hidden;

  transition: background 0.5s ease-in-out;

  .intro-wave-wrapper {
    bottom: -18vh;
  }

  .top-intro-wave {
    // height: 80;
  }

  &.letsBeginBG {
    background: var(--lightBlue);
  }

  .syd-water-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: all 0.5s linear;
    z-index: 2;

    &.anim {
      top: 10%;
      transform: translate(-50%, -50%) scale(0.8);
    }
  }

  .white-syd-water-logo {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0;
    z-index: 1;
  }

  .platy-type-two {
    position: absolute;
    z-index: 1;
    top: 30%;
    left: -100%;
    transform: translate(-50%, -50%) scaleX(-1);
    height: 30vh;
  }

  .wonders-of-water {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
    transition: all 0.5s ease-in;
    &.anim {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .intro-wave-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .intro-wave-inner {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateX(100%);
        max-width: none;
      }
    }
  }

  .title {
    font-size: 1.425em;
    font-family: "Mont";
    font-weight: bold;
  }
  .body {
    font-size: 0.72em;
    font-family: "Mont";
    font-weight: 300;
    line-height: 1.5;
  }

  .content-wrapper {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    padding: 0 2em;
    opacity: 0;
    color: #fff;
  }

  .before-we-start-wrapper {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    padding: 0 2em;
    color: #fff;
    opacity: 0;
  }

  .knowledge-indicator-wrapper {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    pointer-events: none;
    opacity: 0;
    padding: 0 2em;

    .range-slider {
      appearance: none;
      border: none;
      width: 100%;
      height: 0.625em;
      background-color: var(--green);
      border-radius: 5px;
      box-shadow: none;
    }

    .range-slider::-webkit-slider-thumb {
      appearance: none;
      border: none;
      width: 72px;
      height: 61px;
      background: url(${RangeSliderPlaty});
      background-size: cover;
      background-position: 3px center;
      background-repeat: no-repeat;
      cursor: pointer;
      box-shadow: none;
    }

    .range-slider::-moz-range-thumb,
    .range-slider::-ms-thumb {
      appearance: none;
      border: none;
      background: url(${RangeSliderPlaty});
      width: 72px;
      height: 61px;
      background-size: cover;
      background-position: 3px center;
      background-repeat: no-repeat;
      cursor: pointer;
      box-shadow: none;
    }

    .val-indicator {
      font-size: 2.625em;
      font-family: "Mont";
      font-weight: 300;
      color: var(--orange);
      padding: 0 0 0.1em 0;
      border-bottom: 4px solid var(--orange);
      width: fit-content;
      text-align: center;
      margin: 0.5em auto;
    }
  }

  .disabled {
    filter: grayscale(1);
  }

  p.first-vo {
    opacity: 0;
    transition: opacity 0.5s linear;
    width: 85vw;
    margin-bottom: 3vh;

    &.active {
      opacity: 1;
    }
  }
`;

const Landing = () => {
  const { initialiseAR } = useContext(ConfigContext);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [initialAnim, setInitialAnim] = useState(false);
  const [rangeSliderVal, setRangeSliderVal] = useState(1);
  const [letsBegin, setLetsBegin] = useState(false);
  const [playFirstVO, setPlayFirstVO] = useState(false);

  const setCurrentVO = useSetOnBoardingVO();
  // const currentVO = useOnBoardingVO();

  const introWaveInner = useRef(null);
  const topIntroWave = useRef(null);
  const bigBottomIntroWave = useRef(null);
  const wondersOfWaterLogo = useRef(null);
  const platyTypeTwoRef = useRef(null);

  const handleLetsBegin = () => {
    setIsPromptOpen(false);
    setLetsBegin(true);
    setCurrentVO(voSurvey);

    gsap.to(topIntroWave.current, {
      duration: 1,
      translateY: window.innerWidth > 413 ? "-90%" : "-10%",
      scale: window.innerWidth > 413 ? 2 : 1
    });
    gsap.to(bigBottomIntroWave.current, {
      duration: 1,
      translateY: window.innerWidth > 413 ? "-50%" : "0%",
      scale: window.innerWidth > 413 ? 2 : 1
    });
    gsap.to([".content-wrapper", ".syd-water-logo"], {
      duration: 1,
      opacity: 0,
      pointerEvents: "none"
    });
    gsap.to(
      [
        ".white-syd-water-logo",
        ".before-we-start-wrapper",
        ".knowledge-indicator-wrapper"
      ],
      {
        duration: 1,
        opacity: 1,
        pointerEvents: "auto"
      }
    );
    gsap.to(platyTypeTwoRef.current, {
      translateX: "100%"
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setInitialAnim(true);
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      gsap.fromTo(
        platyTypeTwoRef.current,
        {
          top: "30%",
          left: "-100%"
        },
        {
          delay: 1.75,
          duration: 2,
          ease: "power3.out",
          top: "40%",
          left: "50%"
        }
      );
      gsap.fromTo(
        bigBottomIntroWave.current,
        {
          translateY: `${51.7 / (window.innerWidth > 413 ? 2 : 1)}%`,
          translateX: `${100 * (window.innerWidth > 413 ? 2 : 1)}%`,
          scale: window.innerWidth > 413 ? 2 : 1
        },
        {
          duration: 1,
          ease: "power3.out",
          translateX: window.innerWidth > 413 ? "-50%" : "0%",
          translateY: `${51.7 / (window.innerWidth > 413 ? 2 : 1)}%`,
          scale: window.innerWidth > 413 ? 2 : 1,

          onComplete: () => {
            gsap.to(bigBottomIntroWave.current, {
              duration: 1,
              delay: 2,
              ease: "power3.out",
              translateY: window.innerWidth > 413 ? "5%" : "2%",
              onComplete: () => {
                gsap.to(".content-wrapper", {
                  duration: 1,
                  opacity: 1,
                  pointerEvents: "auto"
                });
              }
            });
          }
        }
      );
      gsap.fromTo(
        topIntroWave.current,
        {
          translateX: `${100 * (window.innerWidth > 413 ? 2 : 1)}%`,
          scale: window.innerWidth > 413 ? 2 : 1
        },
        {
          duration: 1,
          ease: "power3.out",
          translateY: window.innerWidth > 413 ? "-20%" : "0%",
          translateX: window.innerWidth > 413 ? "-50%" : "-10%",
          scale: window.innerWidth > 413 ? 2 : 1.2
        }
      );

      gsap.to(wondersOfWaterLogo.current, {
        duration: 0.5,
        delay: -0.5,
        opacity: 0,
        ease: "linear"
      });
    }, 2500);
  }, []);

  const handleRangeSlider = (e) => {
    setMovedSlider(true);
    setRangeSliderVal(e.target.value);
  };

  const [movedSlider, setMovedSlider] = useState(false);

  const handleSubmit = async () => {
    if (!movedSlider) return;
    setCurrentVO("");
    try {
      // console.log("rangeSliderVal", rangeSliderVal);
      //! UNCOMMENT WHEN LIVE
      // const response = await fetch(
      //   "https://australia-southeast1-sydney-water-345002.cloudfunctions.net/quizApi",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       rate: rangeSliderVal,
      //     }),
      //   },
      // );
      // console.log("response", response);
    } catch (error) {
      console.log(error);
    }
    initialiseAR();
  };

  const handleVO = () => {
    setPlayFirstVO(true);
    setCurrentVO(voIntro);
  };

  return (
    <LandingWrapper
      id="landing-page"
      className={`${letsBegin ? "letsBeginBG" : ""}`}
    >
      {/* <audio src={currentVO} autoPlay></audio> */}

      {isPromptOpen && (
        <CookiePrompt handleClose={() => setIsPromptOpen(false)} />
      )}

      <img
        src={SydneyWaterLogo}
        alt="Sydney Water Logo"
        className={`syd-water-logo ${initialAnim ? "anim" : ""}`}
      />

      <img
        src={WhiteSydneyWaterLogo}
        alt="Sydney Water Logo"
        className="white-syd-water-logo"
      />

      <img
        src={WondersOfWaterLogo}
        alt="Wonders of water logo"
        className={`wonders-of-water ${initialAnim ? "anim" : ""}`}
        ref={wondersOfWaterLogo}
      />

      <img
        src={platyLoop_typeTwo}
        alt="platy type two"
        className={`platy-type-two`}
        ref={platyTypeTwoRef}
      />

      <div className="intro-wave-wrapper">
        <div className="intro-wave-inner" ref={introWaveInner}>
          <img
            src={TopIntroWaveImage}
            alt="top intro wave image"
            className="top-intro-wave"
            ref={topIntroWave}
          />
          <img
            src={BigBottomIntroWaveImage}
            alt="big bottom intro wave image"
            className="big-bottom-intro-wave"
            ref={bigBottomIntroWave}
          />
        </div>
      </div>

      <div className="content-wrapper">
        <div className={`title first-vo ${playFirstVO ? "active" : ""}`}>
          {!playFirstVO ? "Turn sound on!" : "Hi, I'm Jila."}
        </div>
        <p className={`body first-vo ${playFirstVO ? "active" : ""}`}>
          Let's discover the Wonders of Water and learn about the urban water
          cycle that ensures no matter the source, water can be made safe for
          drinking!
        </p>
        {playFirstVO ? (
          <DefaultBtn text="Let's Begin" onClick={handleLetsBegin} />
        ) : (
          <DefaultBtn text="Start" onClick={handleVO} />
        )}
      </div>

      <div className="before-we-start-wrapper">
        <div className="title">Before we start</div>
        <p className="body">
          on a scale of 1-10, how much would you rate your knowledge of the
          water cycle already?
        </p>
        <p className="body">Let's go with the water flow...</p>
      </div>

      <div className="knowledge-indicator-wrapper">
        <input
          type="range"
          min={1}
          max={10}
          value={rangeSliderVal}
          className="range-slider"
          onChange={(e) => handleRangeSlider(e)}
          name="rate"
        />

        <p className="val-indicator">{rangeSliderVal}</p>

        <DefaultBtn
          text="Submit"
          className={movedSlider ? "" : "disabled"}
          onClick={handleSubmit}
        />
      </div>

      {/* {arType === "8thwall" && (
        <img
          className='eighth-wall-logo'
          src='//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg'
          alt='8th Wall Logo'
        />
      )} */}
    </LandingWrapper>
  );
};

export default Landing;
