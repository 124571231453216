const theme = {
  color: {
    primary: "#ED4799",
    secondary: "#EA187F",
    text: {
      normal: "#1C1C1C",
      inverted: "#FFFFFF",
    }, 
    base: {
      dark: "#212529",
      light: "#FFFFFF",
      grey: "#DEDBDB",
    },
    placement: {
      default: "#757575",
      success: "#1AE8B6",
      error: "#FF866B",
    }
  },
}

export default theme;
