import React, { useEffect, useState, createContext } from "react";

import { usePC } from "@unbnd-operations/react-hooks";
import { checkDevice } from "@unbnd-operations/utilities";

export const ConfigContext = createContext();

const ConfigState = (props) => {
  /**
   * Settings
   */
  const isXR8Android = false,
    isXR8iOS = false,
    portraitEnabled = true,
    landscapeEnabled = true;

  /**
   * Device Related
   */
  const [error, setError] = useState(null); // Error Screens
  const [isTapToPlace, setIsTapToPlace] = useState(false); // Tap to Place
  const [arType, setArType] = useState(null); // AR Technology
  const [deviceInfo, setDeviceInfo] = useState(null); // Device information

  /**
   * PlayCanvas Loading
   */
  const [loadingProgress, setLoadingProgress] = useState(0); // Playcanvas loader
  const [sceneLoaded, setSceneLoaded] = useState(false); // Playcanvas ready

  /**
   * Setup phase
   */
  const [arStarted, setArStarted] = useState(false); // AR Started
  // const [arFound, setArFound] = useState(false); // Surface found (WebXR)
  // const [arPlaced, setArPlaced] = useState(false); // Object placed
  const [setupDone, setSetupDone] = useState(false); // Checkbox ticked

  // Image tracking states
  const [imageFound, setImageFound] = useState(false); // Image found

  // * Error screen handling
  // *-------------------------------------* /
  // Handle permission errors (camera, motion)
  usePC({
    event: "ar:error:permission",
    callback: ({ reason }) => {
      setError(reason);
    },
  });

  // Handle browser error
  usePC({
    event: "ar:error:browser",
    callback: () => {
      setError("browser");
    },
  });

  // Trigger a desktop error - {Available in context}
  const setDesktopError = () => {
    setError("desktop");
  };

  // * AR Type
  // *-------------------------------------* /
  // Check AR Type
  usePC({
    event: "ar:type",
    callback: ({ type }) => {
      setArType(type);
    },
  });

  // * PlayCanvas Loading
  // *-------------------------------------* /
  // Loading progress (from PlayCanvas)
  usePC({
    event: "preload:progress",
    callback: (progress) => {
      setLoadingProgress(progress);
    },
  });

  // Loading done function
  usePC({
    event: "start",
    callback: () => {
      setSceneLoaded(true);
    },
  });

  // * Start AR
  // *-------------------------------------* /
  // Initialise AR - {Avaiable in context}
  const [initialiseAR] = usePC({ event: "ar:init" });

  // AR Ready
  usePC({
    event: "ar:started",
    callback: () => {
      setArStarted(true);
    },
  });

  // Setup Done
  const [completeSetup] = usePC({
    event: "ar:setup:done",
    callback: () => {
      setSetupDone(true);
    },
  });

  usePC({
    event: "ar:image:found",
    callback: () => {
      setImageFound(true);
    },
  });

  usePC({
    event: "ar:image:lost",
    callback: () => {
      setImageFound(false);
    },
  });

  // RecenterAR Function - {Available in context}
  const [recenterAR] = usePC({ event: "ar:recenter" });

  // * Get device information
  // *-------------------------------------* /
  useEffect(() => {
    const thisDevice = checkDevice();

    setDeviceInfo(thisDevice);
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        isXR8Android,
        isXR8iOS,
        portraitEnabled,
        landscapeEnabled,
        deviceInfo,
        error,
        arType,
        loadingProgress,
        sceneLoaded,
        arStarted,
        // arFound,
        // arPlaced,
        setupDone,
        setDesktopError,
        initialiseAR,
        setArStarted,
        // placeAR,
        completeSetup,
        // pickupAR,
        recenterAR,
        imageFound,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigState;

// * Placement functions
// *-------------------------------------* /
// Handle Surface Found
// usePC({
//   event: 'ar:surface:found',
//   callback: () => {
//     setArFound(true)
//   }
// });

// Handle Place AR
// const [handleARPlace] = usePC({
//   event: 'ar:placed',
//   callback: () => {
//     setArPlaced(true);
//   }
// });

// Handle Putdown AR
// const [handleARPutdown] = usePC({
//   event: 'ar:putdown',
//   callback: () => {
//     setArPlaced(true)
//   }
// });

// PlaceAR Function - {Available in context}
// const placeAR = (firstPlace) => {
//   if (firstPlace) {
//     handleARPlace();
//   } else {
//     handleARPutdown();
//   }
// };

// PickupAR Function - {Available in context}
// const [pickupAR] = usePC({
//   event: 'ar:pickup',
//   callback: () => {
//     setArPlaced(false);
//   }
// });
