import React from "react";
import styled from "styled-components";

const SpeechBubbleWrapper = styled.div`
  position: absolute;

  &.active {
    .inner {
      opacity: 1;
    }

    &::after {
      opacity: 1;
    }
  }

  .inner {
    width: 100%;
    height: auto;
    left: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 100px;
    padding: 1.25em 1.25em;
    text-align: center;
    position: absolute;
    font-family: "Mont";
    font-weight: 800;
    color: var(--marine);
    font-size: 0.75em;
    transition: opacity 0.5s linear;
    opacity: 0;
    border-bottom: 3px solid var(--marine);
    border-left: 3px solid var(--marine);
  }

  .circles {
    position: absolute;
    bottom: -16px;
    left: -6px;
    .circle-one,
    .circle-two {
      position: relative;
      background-color: #fff;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-55%, -45%);

        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: var(--marine);
        z-index: -1;
      }
    }

    .circle-two {
      width: 6px;
      height: 6px;
      top: -100%;
      left: -100%;
    }
  }

  p {
    margin: 0;
  }
`;

function SpeechBubble({
  children,
  leftPos,
  botPos,
  size = "large",
  active,
  flip,
}) {
  return (
    <SpeechBubbleWrapper
      className={`speech-bubble-wrapper ${active ? "active" : ""}`}
      style={{
        bottom: `${botPos}px`,
        left: `${leftPos}px`,
        width: size === "large" ? "268px" : "177px",
      }}
    >
      <div className='inner'>
        {children}

        <div
          className='circles'
          style={{
            transform: flip ? "scaleX(-1)" : "scaleX(1)",
          }}
        >
          <div className='circle-one'></div>
          <div className='circle-two'></div>
        </div>
      </div>
    </SpeechBubbleWrapper>
  );
}

export default SpeechBubble;
