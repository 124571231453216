import React from "react";
import SydneyWaterLogo from "../../assets/images/sydney-water-logo.svg";
import WondersOfWaterLogo from "../../assets/images/wonders-of-water-logo.svg";

const Camera = () => {
  return (
    <div className='container'>
      <img
        src={SydneyWaterLogo}
        alt='Sydney Water logo'
        className='sydney-water-logo'
      />
      <p>
        The camera permission were denied on launch. Please refresh your browser
        and press allow on the pop-up.
      </p>
      <img
        src={WondersOfWaterLogo}
        alt='Wonders of Water logo'
        className='wonders-water-logo'
      />
    </div>
  );
};

export default Camera;
