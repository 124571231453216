import React, { useContext } from "react";
import styled from "styled-components";

import Camera from "./Camera";
import Motion from "./Motion";
import Browser from "./Browser";
import Desktop from "./Desktop";

import { ConfigContext } from "../../global/ConfigState";

const ErrorScreensWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: var(--baseLight);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 1.5rem;
  z-index: 99999;
  pointer-events: auto;

  .heading {
    margin-bottom: 1rem;
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 18px 0;

    font-family: "Mont";
    font-weight: 300;
    line-height: 1.5;
    img {
      flex: 0 1 10%;
      margin: 0 auto;
    }

    .sydney-water-logo {
      width: 120px;
    }

    .wonders-water-logo {
      width: 90px;
    }

    .btn-copy {
      border: none;
      background: none;
      font-family: "Mont";
      font-weight: 800;
      text-transform: uppercase;
      font-size: 12px;
      color: #28388e;
    }
    .copy-wrapper {
      margin-bottom: 2rem;
      .error-heading {
        margin-bottom: 0.75rem;
      }
    }

    .qr-code {
      width: 250px;
      height: 250px;
      margin: 0 auto;
    }

    &.desktop {
      .sydney-water-logo {
        width: 200px;
      }

      .wonders-water-logo {
        width: 160px;
      }
    }
  }
`;

const Error = () => {
  const { error } = useContext(ConfigContext);

  return (
    <ErrorScreensWrapper>
      {error === "camera" && <Camera />}
      {error === "motion" && <Motion />}
      {error === "browser" && <Browser />}
      {error === "desktop" && <Desktop />}
    </ErrorScreensWrapper>
  );
};

export default Error;
