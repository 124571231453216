import React, { useContext } from "react";
import styled from "styled-components";

import { ConfigContext } from "../../global/ConfigState";

import LineLoader from "../../components/loaders/LineLoader";

import BackgroundImage from "../../assets/images/background.png";
import SydneyWaterLogo from "../../assets/images/sydney-water-logo.svg";

const LoadingScreen = styled.section`
  width: 100%;
  height: 100%;
  background: url(${BackgroundImage}) no-repeat center center fixed;
  background-size: cover;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  display: grid;
  place-items: center;

  .syd-water-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loader-wrapper {
    width: 60%;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    color: var(--marine);

    .number-progress {
      position: relative;
      text-align: center;
    }
  }
`;

const Loading = () => {
  const { arType, loadingProgress, deviceInfo, isXR8Android, isXR8iOS } =
    useContext(ConfigContext);

  return (
    <LoadingScreen id='loading-screen'>
      <img
        src={SydneyWaterLogo}
        alt='Sydney Water Logo'
        className='syd-water-logo'
      />

      <div className='loader-wrapper'>
        <LineLoader progress={loadingProgress} barColour='#ED4799' />
        <p className='number-progress'>{Math.floor(loadingProgress * 100)}%</p>
      </div>

      {(arType === "8thwall" ||
        (deviceInfo.os === "Android" && isXR8Android) ||
        (deviceInfo.vendor === "Apple" && isXR8iOS)) && (
        <img
          className='eighth-wall-logo'
          src='//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg'
          alt='8th Wall Logo'
        />
      )}
    </LoadingScreen>
  );
};

export default Loading;
