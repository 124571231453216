import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    --headingFont: inherit;
    --primaryFont: inherit;
    --aqua: #00FFFF;
    --marine: #001ECD;
    --purple: #9EA1FF;
    --green: #46E6BE;
    --stone: #BBBABB;
    --mediumBlue: #00259B;
    --lightBlue: #2BACCC;
    --orange: #FBC310;
    --primaryColour: ${(props) => props.theme.color.primary};
    --secondaryColour: ${(props) => props.theme.color.secondary};
    --textColour: ${(props) => props.theme.color.text.normal};
    --invertedTextColour: ${(props) => props.theme.color.text.inverted};
    --baseDark: ${(props) => props.theme.color.base.dark};
    --baseLight: ${(props) => props.theme.color.base.light};
    --baseGrey: ${(props) => props.theme.color.base.grey};
    --placementError: ${(props) => props.theme.color.placement.error};
    --placementSuccess: ${(props) => props.theme.color.placement.success};
    --placementBase: ${(props) => props.theme.color.placement.base};
  }


  .btn-wrapper {
    position: relative;
    font-family: "Mont";
    font-weight: 800;
    font-size: 0.750em;
    color: #fff;
    width: auto;
    .default-btn {
      border: none;
      background-color: #fff;
      font-size: 1rem;
      color: var(--textColour);

      width: 56px;
      height: 56px;
      border-radius: 50%;
      position: relative;

      margin: 0 auto;

      display: flex;
      align-items: center;
      justify-content: center;


      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-55%, -45%);
        width: 100%;
        height: 100%;
        background-color: var(--green);
        z-index: -1;
        border-radius: 50%;
      }

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }

    &.experience-btn {
      position: absolute;
      bottom: 5%;
      right: 5%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      .default-btn {
        &:after {
          background-color: var(--marine);
        }
      }
      .btn-text {
        margin-right: 0.75em;
      }
    }
  }

  
  a {
    &:hover, 
    &:focus {
      color: var(--primaryColour);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--headingFont);
    line-height: 1.25;
    margin-bottom: .25em;
  }

  .scrollbar-remove {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
`;
