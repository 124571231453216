import React, { useContext, useEffect, useRef } from "react";

import { ConfigContext } from "../../global/ConfigState";

import Setup from "./Setup";
import Rotate from "../../components/helpers/Rotate.js";
import ImageTracker from "./ImageTracker";
import Experience from "./Experience";

import { usePC } from "@unbnd-operations/react-hooks";

const ARWrapper = () => {
  const { imageFound, setupDone } = useContext(ConfigContext);
  const soundtrackRef = useRef(null);
  // console.log("imageFound", imageFound);

  const [audioPlay] = usePC({
    event: "audio:play"
  });

  useEffect(() => {
    audioPlay({ name: "birds" });
  }, []);

  return (
    <>
      <Rotate />
      <Experience visible={imageFound} />
      {!imageFound && <ImageTracker />}
    </>
  );
};

export default ARWrapper;
