import React from "react";
import styled from "styled-components";

import ImageTrackOutline from "../../assets/images/image-track-outline.svg";
import StillPlaty from "../../assets/images/still-platy.png";
import SpeechBubble from "../../components/SpeechBubble";

const ImageTrackerWrapper = styled.div`
  width: 100%;
  height: 100%;

  .image-track-outline {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .still-platy {
    position: absolute;
    left: -30%;
    bottom: 0;
  }
`;

function ImageTracker() {
  return (
    <ImageTrackerWrapper>
      <img
        src={ImageTrackOutline}
        alt="image track outline"
        className="image-track-outline"
      />

      <SpeechBubble botPos={130} leftPos={180} size="small" active={true}>
        <p>Please walk forward and line-up the water cycle map with frame</p>
      </SpeechBubble>
      <img src={StillPlaty} alt="Still platy" className="still-platy" />
    </ImageTrackerWrapper>
  );
}

export default ImageTracker;
