import React, { useContext, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ConfigContext } from "../global/ConfigState";

import Loading from "./Loading/Loading";
import Landing from "./Landing/Landing";
import ARWrapper from "./Experience/ARWrapper";
import Error from "./Error/Error";
import CookiePolicy from "./CookiePolicy/CookiePolicy";
import { useOnBoardingVO } from "../global/AppStore";

const Main = () => {
  const { sceneLoaded, deviceInfo, error, arStarted, setDesktopError } =
    useContext(ConfigContext);
  const audioTagRef = useRef(null);

  const onBoardingVO = useOnBoardingVO();

  useEffect(() => {
    if (!deviceInfo) return;

    if (deviceInfo.type === "desktop") {
      return setDesktopError();
    }

    const startScript = document.createElement("script");
    startScript.src = "/scene/__start__.js";
    document.body.appendChild(startScript);
  }, [deviceInfo]);

  return (
    <>
      <audio ref={audioTagRef} src={onBoardingVO} autoPlay></audio>

      {deviceInfo && (
        <Router>
          <Switch>
            <Route exact path='/cookie-policy' component={CookiePolicy} />
            <Route exact path='/'>
              <>
                {error && <Error />}

                {!sceneLoaded ? (
                  <Loading />
                ) : (
                  <>{arStarted ? <ARWrapper /> : <Landing />}</>
                )}
              </>
            </Route>
          </Switch>
        </Router>
      )}
    </>
  );
};

export default Main;
