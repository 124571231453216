import React, { useContext} from 'react'
import styled from 'styled-components'

import { ConfigContext } from '../../global/ConfigState'


const RotateScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  background-color: var(--baseLight);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  opacity: 0;
  pointer-events: none;

  .rotate-icon {
    path {
      fill: var(--primaryColour);
    }
  }

  @media screen and (orientation: portrait) {
    opacity: ${(props) => props.portrait ? 0 : 1};
    pointer-events: ${(props) => props.portrait ? 'none' : 'auto'};


  }

  @media screen and (orientation: landscape) {
    opacity: ${(props) => props.landscape ? 0 : 1};
    pointer-events: ${(props) => props.landscape ? 'none' : 'auto'};

  }
`

const RotateSVG = () => {
  return (
    <svg className="rotate-icon" width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.5718 4.57139C52.286 3.99997 51.7146 3.42854 51.1432 3.14282C51.4289 3.42854 52.0003 3.99997 52.5718 4.57139Z" fill="black"/>
      <path d="M4.5711 2.85718C3.99967 3.42861 3.42824 4.00003 2.85681 4.57146C3.42824 4.00003 3.99967 3.42861 4.5711 2.85718Z" fill="black"/>
      <path d="M81.1432 33.1429C80.8575 32.5715 80.286 32.0001 79.7146 31.7144C80.0003 32.0001 80.5717 32.5715 81.1432 33.1429Z" fill="black"/>
      <path d="M2.85681 33.1429C3.14253 32.5715 3.71395 32.0001 4.28538 31.7144C3.99967 32.0001 3.42824 32.5715 2.85681 33.1429Z" fill="black"/>
      <path d="M27.7146 72.8571C28.977 72.8571 30.0003 71.8337 30.0003 70.5714C30.0003 69.309 28.977 68.2856 27.7146 68.2856C26.4522 68.2856 25.4289 69.309 25.4289 70.5714C25.4289 71.8337 26.4522 72.8571 27.7146 72.8571Z" fill="black"/>
      <path d="M81.1429 33.1427C80.8572 32.5713 80.2857 31.9999 79.7143 31.7141C78.5715 30.857 77.4286 30.5713 76.2857 30.5713H59.1429C59.1429 32.2856 60.2857 33.4284 62 33.4284H76.2857C78 33.4284 79.1429 34.5713 79.1429 36.2856V76.2856C79.1429 77.9999 78 79.1427 76.2857 79.1427H62C60.2857 79.1427 59.1429 80.2856 59.1429 81.9999H76.2857C79.4286 81.9999 82 79.4284 82 76.2856V36.2856C82 35.1427 81.7143 33.9999 81.1429 33.1427Z" fill="black"/>
      <path d="M52.5714 4.57143C52.2857 4 51.7143 3.42857 51.1429 3.14286C50 2.28571 48.8571 2 47.7143 2H7.71429C6.57143 2 5.42857 2.28571 4.57143 2.85714C4 3.42857 3.42857 4 2.85714 4.57143C2.28571 5.42857 2 6.57143 2 7.71429V36.2857V76.2857C2 78.2857 3.14286 80.2857 4.85714 81.1429V76.2857V36.2857V31.4286V7.71429C4.85714 6 6 4.85714 7.71429 4.85714H47.7143C49.4286 4.85714 50.5714 6 50.5714 7.71429V36.2857V39.1429V76.2857C50.5714 78 49.4286 79.1429 47.7143 79.1429H13.9048C12.1905 79.1429 11.0476 80.2857 11.0476 82H47.7143C49.7143 82 51.7143 80.8571 52.5714 79.1429C53.1429 78.2857 53.4286 77.4286 53.4286 76.2857V39.1429V36.2857V7.71429C53.4286 6.57143 53.1429 5.42857 52.5714 4.57143ZM2.85714 33.1429C3.14286 32.5714 3.71429 32 4.28571 31.7143C4 32 3.42857 32.5714 2.85714 33.1429Z" fill="black"/>
      <path d="M59.1429 7.99988C59.1429 9.42845 60.2857 10.5713 61.4286 10.857C67.4286 11.9999 72 16.5713 73.1429 22.5713C73.4286 23.7142 74.5715 24.857 76 24.857H76.2857C76.2857 15.4284 68.5715 7.71416 59.1429 7.99988Z" fill="black"/>
    </svg>
  )
}

const Rotate = () => {
  const { portraitEnabled, landscapeEnabled } = useContext(ConfigContext);

  return (
    <RotateScreenWrapper 
      id='rotate-screen-wrapper' 
      portrait={portraitEnabled} 
      landscape={landscapeEnabled}
    >
      <RotateSVG />
      <p>Please rotate your device.</p>
    </RotateScreenWrapper>
  )
}

export default Rotate
