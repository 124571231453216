export const data = [
  {
    id: 0,
    copy: "Move your device around each stage of the water cycle to learn more about each label.",
    timeout: 2500,
  },
  {
    id: 1,
    copy: "Evaporation is when the sun shines on water and heats it, turning it into gas called water vapour which rises into the air.",
    timeout: 2500,
  },
  {
    id: 2,
    copy: "Precipitation is when water droplets fall from the sky as rain, snow or hail.",
    timeout: 1500,
  },
  {
    id: 3,
    copy: "Rain falling in catchments is currently the main source of Sydney's drinking water. Dams are built across rivers to store water for our water supply.",
    timeout: 3000,
  },
  {
    id: 4,
    copy: "When water is filtered, debris, like twigs are sieved. Smaller particles are bound together into 'flocs' and removed. The pH of the water is balanced and chlorine is added to protect from pathogens.",
    timeout: 3500,
  },
  {
    id: 5,
    copy: "From our filtration plants, we send your water to secure covered reservoirs. Our pipes connect the reservoirs to the taps in your home.",
    timeout: 3000,
  },
  {
    id: 6,
    copy: "Our Recycled Water Reservoirs store recycled water so it can be supplied when needed. It enters through a purple pipe and can be used for non-drinking purposes such as gardens, washing machines and toilets. Using recycled water reduces waste and the need to depend on rainfall.",
    timeout: 3000,
  },
  {
    id: 7,
    copy: "Our Resource Recovery Facilities return flows into the environment.",
    timeout: 1500,
  },
  {
    id: 8,
    copy: "Wastewater treatment facilities make sure that every day wastewater doesn't create a hazard for Greater Sydney. We treat it and, where possible, recycle and reuse it.",
    timeout: 3000,
  },
  {
    id: 9,
    copy: "The 28 wastewater treatment plants that we own and operate make sure that more than 1.3 billion litres of wastewater produced every day by over 1.8 million homes and businesses in Sydney can be returned to the environment or reused safely.",
    timeout: 3500,
  },
  {
    id: 10,
    copy: "We treat wastewater before it's reused or discharged to rivers or oceans, monitoring the effect of discharges on water quality and aquatic life.",
    timeout: 3000,
  },
  {
    id: 11,
    copy: "Stormwater harvesting in urban areas involves collecting water from drains or creeks. Treating and storing the water for later use.",
    timeout: 3000,
  },
  {
    id: 12,
    copy: "The ocean can be used as a source of drinking water. Desalination is the process of removing the salt from seawater to produce drinking water.",
    timeout: 3000,
  },
  {
    id: 13,
    copy: "The desalination plant takes seawater from about 300m offshore and 25m below the surface. It uses reverse osmosis to turn it into drinking water.",
    timeout: 3000,
  },
  {
    id: 14,
    copy: "WOW! Now we know how Sydney Water manages the water cycle to ensure that our water is safe to drink. Wonder how cities around the world source water? Move to the next station to try the Global Guesser.",
    timeout: 3500,
  },
];
