import React from "react";
import QrCode from "../../components/helpers/QrCode";

import SydneyWaterLogo from "../../assets/images/sydney-water-logo.svg";
import WondersOfWaterLogo from "../../assets/images/wonders-of-water-logo.svg";

const Desktop = () => {
  return (
    <div className='container desktop'>
      <img
        src={SydneyWaterLogo}
        alt='Sydney Water logo'
        className='sydney-water-logo'
      />
      <div className='body-wrapper'>
        <div className='copy-wrapper'>
          <h2 className='heading error-heading'>
            This is a mobile experience!
          </h2>
          <p>
            Please scan the below qr code to open the experience on your smart
            phone.
          </p>
        </div>
        <QrCode fg='#28388e' bg='#FFFFFF' url={window.location.href} />
      </div>
      <img
        src={WondersOfWaterLogo}
        alt='Wonders of Water logo'
        className='wonders-water-logo'
      />
    </div>
  );
};

export default Desktop;
