import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const CookiePromptWrapper = styled.div `
  background-color: #cecece;
  position: absolute;
  top: .75rem;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  padding: 0.5rem 1rem;
  pointer-events: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: .5rem;
  z-index: 10;

  .cookie-paragraph {
    font-size: 0.75rem;
    line-height: 1.3;
    padding-right: 1rem;
  }

  a {
    text-decoration: underline;
  }

  .close-btn {
    right: 0;
    top: 0;
    background-color: transparent;
    position: relative;

    span {
      &::before, &::after {
        background-color: #000;
      }
    }
  }
`

const CookiePrompt = ({handleClose}) => {
  return (
    <CookiePromptWrapper id='cookie-prompt'>
      <p className="cookie-paragraph">This site uses cookies to improve the experience. By clicking start, you accept our <Link to="/cookie-policy">cookie policy.</Link></p>
      <button onClick={handleClose}>Close</button>
    </CookiePromptWrapper>
  )
}

export default CookiePrompt