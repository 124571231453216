import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

import ConfigState from './global/ConfigState'
import AppState from './global/AppState'

import { ThemeProvider } from 'styled-components'
import { GlobalStyles }  from './styles/GlobalStyles'
import theme from './styles/_theme'

import Main from './pages/Main'

function App() {
  /**
   * Initalise Google Analytics
   */
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID, {
      debug: process.env.REACT_APP_ENV !== "production",
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ConfigState>
        <AppState>
          <Main />
        </AppState>
      </ConfigState>
    </ThemeProvider>
  )
}

export default App
