import React, { useState, createContext } from 'react'

export const AppContext = createContext();

const AppState = (props) => {
  const [example, setExample] = useState('');

  return (
    <AppContext.Provider value={{
      example
    }}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppState
