import React from "react";
import StartArrow from "../../assets/images/start-arrow.svg";
import StartArrowBlue from "../../assets/images/start-arrow-blue.svg";

function DefaultBtn({ text, onClick, className }) {
  return (
    <div
      className={`btn-wrapper ${className ? className : ""}`}
      onClick={onClick}
    >
      <button className='default-btn'>
        <img
          src={className === "experience-btn" ? StartArrowBlue : StartArrow}
          alt='start arrow'
        />
      </button>
      <p className='btn-text'>{text}</p>
    </div>
  );
}

export default DefaultBtn;
