import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useLayoutEffect
} from "react";
import { usePC } from "@unbnd-operations/react-hooks";
import styled from "styled-components";
import gsap from "gsap";

import { data } from "../../data/PlatyData";
import SpeechBubble from "../../components/SpeechBubble";
import DefaultBtn from "../../components/layout/DefaultBtn";
import MoreInformationImage from "../../assets/images/more-information.svg";

import LoopAnim_One from "../../assets/videos/loop_typeOne.png";
import LoopAnim_Two from "../../assets/videos/loop_typeTwoV2.png";

import voLanding from "../../assets/VO/3_landing.mp3";
import voOne from "../../assets/VO/steps/1_evaporation.mp3";
import voTwo from "../../assets/VO/steps/2_rainfall.mp3";
import voThree from "../../assets/VO/steps/3_dam.mp3";
import voFour from "../../assets/VO/steps/4_water-filtration.mp3";
import voFive from "../../assets/VO/steps/5_water-resevoir.mp3";
import voSix from "../../assets/VO/steps/6_recycled-water-resevoir.mp3";
import voSeven from "../../assets/VO/steps/7_return-to-environment.mp3";
import voEight from "../../assets/VO/steps/8_advanced-water-treatment.mp3";
import voNine from "../../assets/VO/steps/9_water-resource-recovery.mp3";
import voTen from "../../assets/VO/steps/10_return-to-environment.mp3";
import voEleven from "../../assets/VO/steps/11_storm-water.mp3";
import voTwelve from "../../assets/VO/steps/12_ocean-water.mp3";
import voThirteen from "../../assets/VO/steps/13_desalination-plant.mp3";
import voFourteen from "../../assets/VO/steps/14_thank-you.mp3";

import {
  useCurrentSlide,
  useCurrentIndex,
  useShowSpeechBubble,
  useSetCurrentSlide,
  useSetCurrentIndex,
  useSetShowSpeechBubble,
  useCurrentVO,
  useSetCurrentVO,
  useHasPlayedLandingVO,
  useSetHasPlayedLandingVO
} from "../../global/AppStore";

const voArray = [
  // voLanding,
  voOne,
  voTwo,
  voThree,
  voFour,
  voFive,
  voSix,
  voSeven,
  voEight,
  voNine,
  voTen,
  voEleven,
  voTwelve,
  voThirteen,
  voFourteen
];

const ExperienceWrapper = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  z-index: -2;

  pointer-events: auto;

  display: none;

  &.visible {
    display: block;
  }

  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.25rem;

    .timeline {
      flex: 0 0 70%;
      width: 100%;
      height: 100%;
      font-family: "Mont";
      font-weight: 800;
      color: #fff;
      display: flex;
      flex-direction: column;

      p {
        margin: 0 0 0.5rem 0;
      }
      .line-container {
        background-color: #fff;
        height: 4px;
        width: 100%;
        max-width: 180px;
        border-radius: 2px;
        overflow: hidden;
        .line {
          background-color: var(--marine);
          height: 100%;
        }
      }
    }

    .menu {
      flex: 0 0 30%;
      text-align: right;
      button {
        background-color: transparent;
        border: none;
        padding: 6px 15px;
      }
    }
  }

  .animation-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    .loop-animation-one {
      transform: translate(-100%, 150%);
      opacity: 0;
    }
    .loop-animation-two {
      transform: translate(100%, 70%) rotate(15deg);
      opacity: 0;
    }
  }
`;

const Experience = ({ visible }) => {
  const [swimSFX, setSwimSFX] = useState(null);

  const currentIndex = useCurrentIndex();
  const currentSlide = useCurrentSlide();
  const currentVO = useCurrentVO();
  const activateSpeechBubble = useShowSpeechBubble();
  const setCurrentIndex = useSetCurrentIndex();
  const setCurrentSlide = useSetCurrentSlide();
  const setCurrentVO = useSetCurrentVO();
  const setActivateSpeechBubble = useSetShowSpeechBubble();
  const hasVOPlayed = useHasPlayedLandingVO();
  const setHasVOPlayed = useSetHasPlayedLandingVO();

  const typeOneRef = useRef(null);
  const typeTwoRef = useRef(null);

  const voRef = useRef(null);
  const sfxRef = useRef(null);

  const [nextAnimation] = usePC({
    event: "next:animation",
    debug: true
  });

  const handleAnimationTrigger = () => {
    setActivateSpeechBubble(false);

    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % data.length);
      setCurrentVO(voArray[currentIndex]);
      setCurrentSlide(data[(currentIndex + 1) % data.length]);
      nextAnimation({ index: (currentIndex + 1) % data.length });
    }, 600);
  };

  const animatePlaty = (type) => {
    if (type === 0) {
      // Reset other animation
      gsap.to(typeTwoRef.current, {
        rotate: "375deg",
        opacity: 0,
        // onStart: () => {
        //   setSwimSFX(null);
        // },
        onComplete: () => {
          gsap.to(typeTwoRef.current, {
            translateX: "-100%",
            translateY: "0",
            duration: 0.8,
            ease: "ease.inOut"
          });
          // setSwimSFX(null);
        }
      });

      gsap.fromTo(
        typeOneRef.current,
        {
          translateX: "-100%",
          translateY: "150%",
          opacity: 0
        },
        {
          delay: 0.8,
          translateX: "-45%",
          translateY: "125%",
          opacity: 1,
          // onStart: () => {
          //   setSwimSFX(SwimInSFX);
          // },
          onComplete: () => {
            setActivateSpeechBubble(true);
            // setSwimSFX(null);
          }
        }
      );
    }
    if (type === 1) {
      // Reset other animation
      gsap.to(typeOneRef.current, {
        translateX: "-100%",
        translateY: "150%"
        // onStart: () => {
        //   setSwimSFX(null);
        // },
      });

      gsap.fromTo(
        typeTwoRef.current,
        {
          translateX: "100%",
          translateY: "0%",
          rotate: "15deg",
          opacity: 0
        },
        {
          delay: 0.5,
          translateX: "0%",
          translateY: "0%",
          opacity: 1,
          // onStart: () => {
          //   setSwimSFX(SwimInSFX);
          // },
          onComplete: () => {
            setActivateSpeechBubble(true);
            // setSwimSFX(null);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (currentIndex < 0) return;
    const animIndex = currentIndex % 2;

    // setCurrentVO(voArray[currentIndex]);
    setCurrentSlide(data[currentIndex]);
    animatePlaty(animIndex);
  }, [currentIndex]);

  const [audioPlay] = usePC({
    event: "audio:play"
  });

  useEffect(() => {
    if (hasVOPlayed) return;
    audioPlay({ name: "landingVO" });
    setHasVOPlayed(true);
  }, []);

  useEffect(() => {
    nextAnimation({ index: 0 });
  }, []);

  return (
    <ExperienceWrapper className={visible && "visible"}>
      <audio ref={voRef} src={currentVO} autoPlay></audio>

      <nav className="nav-wrapper">
        <div className="timeline">
          <p>
            {currentIndex <= 0 ? (
              "Welcome"
            ) : (
              <>
                {currentIndex === data.length - 1 ? "Thanks" : "Timeline"}{" "}
                {currentIndex < 0 ? 0 : currentIndex}/{data.length - 1}
              </>
            )}
          </p>
          <div className="line-container">
            <div
              className="line"
              style={{
                width: (currentIndex / (data.length - 1)) * 100 + "%"
              }}
            ></div>
          </div>
        </div>

        <div className="menu">
          <button>
            <img src={MoreInformationImage} alt="more information btn" />
          </button>
        </div>
      </nav>

      <div className="animation-wrapper">
        <img
          src={LoopAnim_One}
          alt="Type One Animation Loop"
          className="loop-animation-one"
          ref={typeOneRef}
        />

        <img
          src={LoopAnim_Two}
          alt="Type Two Animation Loop"
          className="loop-animation-two"
          ref={typeTwoRef}
        />
      </div>

      {currentIndex !== -1 && (
        <SpeechBubble
          botPos={150}
          leftPos={currentIndex % 2 === 0 ? 100 : 70}
          active={activateSpeechBubble}
          flip={currentIndex % 2 === 0 ? false : true}
        >
          <p>{currentSlide?.copy}</p>
        </SpeechBubble>
      )}

      <DefaultBtn
        text={`${currentIndex >= data.length - 1 ? "Start Again" : "Next"}`}
        className={"experience-btn"}
        onClick={handleAnimationTrigger}
      />
    </ExperienceWrapper>
  );
};

export default Experience;
